
<template>
  <div>
    <v-expansion-panels
      accordion
      active-class="opened-expansion-panel"
      flat
      hover
      multiple
      v-model="displayedPanels"
    >
      <v-expansion-panel class="transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.AssignedAt')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="assigned"
            :document-types="[toggleValueAssigned]"
            :readonly="readonly"
            :excludedItems="excludedRoles"
            :readonlyList="readonly"
            :filters="rolesFilters"
            @change="addVisibleItem"
            multiple
            chip
            ref="assigned-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggleValue.sync="toggleValueAssigned"
                @toggle-type-click="$refs['assigned-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel class="border-bottom transparent">
        <v-expansion-panel-header class="font-weight-medium">{{$t('t.VisibleBy')}}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <typed-doc-picker
            :selected.sync="visible"
            :document-types="[toggleValueVisible]"
            :readonly="readonly"
            :readonlyList="readonly"
            @item-closed="removeAssignedItem"
            multiple
            chip
            ref="visible-picker"
          >
            <template v-slot:append>
              <topic-toggle-filter
                :toggleValue.sync="toggleValueVisible"
                @toggle-type-click="$refs['visible-picker'].handleClick()"
              > </topic-toggle-filter>
            </template>
          </typed-doc-picker>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import IncExc from '@/pages/search/controllers/inc-exc'

export default {
  components: {
    TopicToggleFilter: () => import('../topics/topic-toggle-filter'),
    TypedDocPicker: () => import('@/components/typed-doc-picker')
  },
  data () {
    return {
      externalUserCacheType: CacheType.UserRef,
      toggleValueVisible: 'roles',
      toggleValueAssigned: 'users',
      displayedPanels: [0, 1],
      excludedRoles: ['admins', 'dispute-resolvers', 'sales-reps', 'division-supervisors']
    }
  },
  methods: {
    addVisibleItem (e) {
      this.visible.push(e)
    },
    removeAssignedItem (e) {
      const index = this.assigned.findIndex(i => e.id === i.id)
      if (index > -1) {
        const clonedList = this.lodash.cloneDeep(this.assigned)
        this.assigned.splice(index, 1)
        this.assigned = clonedList
      }
    }
  },
  computed: {
    canUpdate () {
      return !this.readonly && (!this.workQueue?.id || this.workQueue?.createBy === this.$store.getters.currentUser.id)
    },
    rolesFilters () {
      const roles = new IncExc()
      roles.include(['users', 'agent-supervisors'])
      return { roles: roles }
    },
    assigned: {
      get () {
        return this.workQueue.assigned ? this.workQueue.assigned : []
      },
      set (assigned) {
        this.$emit('update:workQueue', Object.assign({}, this.workQueue, { assigned }))
      }
    },
    visible: {
      get () {
        return this.workQueue.visible ? this.workQueue.visible : []
      },
      set (visible) {
        this.$emit('update:workQueue', Object.assign({}, this.workQueue, { visible }))
      }
    }
  },
  props: {
    readonly: Boolean,
    workQueue: Object,
    userId: String
  }
}
</script>

<style lang="stylus" scoped>
.border-bottom
  border-bottom solid 1px var(--bg-plain) !important

.opened-expansion-panel > .v-expansion-panel-header
  border var(--bg-translucid)
  min-height 48px
</style>
